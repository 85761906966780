import { gql } from "@apollo/client";

export const export_orders = gql`
  query Orders(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    orders(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      discount
      net_total
      order_id
      status
      total
      credit_balance
      balance
      clinic_id
      created_at
      payment_method
      payment_status
      payment_detail
      tax
      member_id
      metadata
      member {
        name
        id
        phonenumber
      }
      user_id
      user {
        id
        name
      }
      seller {
        display_name
      }
      order_items {
        product_stock_item {
          name
        }
        member_service_package {
          service_package {
            name
          }
        }
        service {
          name
        }
        service_package {
          name
        }
        quantity
        price
      }
    }
  }
`;
export const getExportOrderVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  take: number;
  skip: number;
}) => {
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  where.clinic_id = { equals: data.clinicId };
  where.created_at = { gte: data.startDate, lte: data.endDate };
  where.status = { in: ["ACTIVE", "DONE"] };
  where.user_id = { notIn: ["self_checkout"] };
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = data.take;
  variables.skip = data.skip;
  return variables;
};

export const export_practitioner = gql`
  query Practitioners(
    $where: PractitionerWhereInput
    $take: Int
    $orderBy: [PractitionerOrderByWithRelationInput!]
    $skip: Int
  ) {
    practitioners(where: $where, take: $take, orderBy: $orderBy, skip: $skip) {
      name

      id
      clinic_id
      _count {
        bookings
        checkins
      }
    }
  }
`;

export const exportPractitionersVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: "CHECKOUT";
  skip: number;
  take: number;
}) => {
  let variables: any = {},
    where: any = {};
  where.clinic_id = { equals: data.clinicId };
  where.from_time = { gte: data.startDate, lte: data.endDate };
  where.status = { in: ["CHECKOUT"] };
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = data.take;
  variables.skip = data.skip;
  return variables;
};

export const export_member = gql`
  query Practitioners(
    $where: PractitionerWhereInput
    $take: Int
    $orderBy: [PractitionerOrderByWithRelationInput!]
    $skip: Int
  ) {
    practitioners(where: $where, take: $take, orderBy: $orderBy, skip: $skip) {
      name

      id
      clinic_id
      _count {
        bookings
        checkins
      }
    }
  }
`;

export const get_top_member_checkin_count = gql`
  query GroupByCheckIn(
    $by: [CheckInScalarFieldEnum!]!
    $where: CheckInWhereInput
  ) {
    groupByCheckIn(by: $by, where: $where) {
      _count {
        _all
      }
      clinic_id
      member_id
    }
  }
`;

export const getTopMemberCheckInCountVar = (data: {
  clinicId: string;
  memberIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.by = ["member_id", "clinic_id"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    member_id: { in: data.memberIds },
  };
  return variables;
};

export const export_checkinout = gql`
  query CheckIns(
    $where: CheckInWhereInput
    $orderBy: [CheckInOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $clinicMembersWhere2: ClinicMemberWhereInput
  ) {
    checkIns(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      clinic_id
      in_time
      created_at
      out_time
      status
      member_id
      member {
        name
        id
        clinic_members(where: $clinicMembersWhere2) {
          name
        }
      }
      practitioner_id
      practitioner {
        name
        id
      }
      service_id
      service {
        name
        id
      }
      merchant_note
      booking {
        service_helper {
          id
          name
        }
      }
      room {
        name
      }
    }
  }
`;

export const getexportCheckInsVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  take: number;
  skip: number;
}) => {
  let variables: any = {},
    where: any = {},
    OR: any[] = [];

  where.clinic_id = { equals: data.clinicId };
  where.in_time = { gte: data.startDate, lte: data.endDate };

  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.clinicMembersWhere2 = { clinic_id: { equals: data.clinicId } };
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = data.take;
  variables.skip = data.skip;
  return variables;
};
